<template>
  <div>
    <QueryForm v-model="queryForm" show-export :query-btn-loading="tableLoading" @handleExport="handleExport" @query="handleQuery" @reset="handleQueryFormReset">

      <el-form-item label="BU">
        <Select
          v-model="queryForm.buIdList"
          api-key="buList"
          :configuration="{
            key: 'id',
            label: 'buName',
            value: 'id'
          }"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="数据版本">
        <el-select
          v-model="queryForm.dataVersion"
          filterable
        >
          <el-option
            v-for="item in dsbVersionOptions"
            :key="item.versionCode"
            :label="item.versionCode"
            :value="item.versionCode"
          />
        </el-select>
      </el-form-item>
    </QueryForm>
    <vxe-table
      v-loading="tableLoading"
      border
      resizable
      show-header-overflow
      height="600"
      :scroll-y="{enabled: false}"
      :scroll-x="{enabled: true}"
      :span-method="mergeRowMethod"
      :data="tableData"
      align="center"
    >
      <vxe-column type="seq" width="60" />
      <vxe-column v-for="(item,index) in columns.inventoryStatsAnalyzeTableColumns" :key="index" :field="item.prop" :title="item.label" :min-width="item.width||'140px'" />
    </vxe-table>

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { dsbVersionList } from '@/api/scm-api'
import { turnOverPageList } from '@/api/configuration'
import Select from '@/components/Selection'
import columns from '@/constant/tablecolumns'

export default {
  components: { QueryForm, Select },

  data() {
    return {
      dsbVersionOptions: [],
      columns,
      tableData: [],
      exportTableData: [],
      queryForm: {
        buIdList: [],
        dataVersion: ''
      },
      tableLoading: false

    }
  },

  watch: {
    dsbVersionOptions(value) {
      if (value && value.length && this.queryForm.dataVersion) {
        this.handleQuery()
      }
    }
  },
  mounted() {
    this._getDsbVersionOptions()
  },
  methods: {
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    async handleExport() {
      const { datas } = await turnOverPageList(this.queryForm)
      const header = columns.inventoryStatsAnalyzeTableColumns.map(v => v.label)
      const filterVal = columns.inventoryStatsAnalyzeTableColumns.map(v => v.prop)
        import('@/vendor/Export2Excel').then((excel) => {
          const data = this.formatJson(filterVal, datas)
          excel.export_json_to_excel({
            header,
            data,
            filename: `全渠道库存统计分析`
          })
        })
    },
    mergeRowMethod({ row, _rowIndex, _columnIndex, column, visibleData }) {
      const fields = ['buName', 'stockChannelName', 'seasonName', 'productSymbolValue', 'positionName', 'productTypeValue']
      const cellValue = row[column.property]
      // 行合并
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
      // 列合并
      if ([4, 5, 6].includes(_columnIndex)) {
        const { productSymbolValue, productTypeValue, positionName } = row
        if (productSymbolValue === productTypeValue && productTypeValue === positionName) {
          return _columnIndex === 4 ? { rowspan: 1, colspan: 3 } : { rowspan: 1, colspan: 0 }
        }
      }
      if ([5, 6].includes(_columnIndex)) {
        const { productTypeValue, positionName } = row
        if (productTypeValue === positionName) {
          return _columnIndex === 5 ? { rowspan: 1, colspan: 2 } : { rowspan: 1, colspan: 0 }
        }
      }
    },
    async _getDsbVersionOptions() {
      const { datas } = await dsbVersionList()
      this.dsbVersionOptions = datas
      this.queryForm.dataVersion = this.dsbVersionOptions[this.dsbVersionOptions.length - 1].versionCode
    },

    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.queryForm.dataVersion = this.dsbVersionOptions[this.dsbVersionOptions.length - 1].versionCode
      this.handleQuery()
    },

    async handleQuery() {
      try {
        this.tableLoading = true
        const { datas } = await turnOverPageList(this.queryForm)
        this.tableData = datas

        this.tableData.map(v => {
          const { productSymbolValue, productTypeValue, positionName } = v
          if (!productSymbolValue && !productTypeValue && !positionName) {
            Object.assign(v, { productSymbolValue: '合计', productTypeValue: '合计', positionName: '合计' })
          } else if (productSymbolValue && !productTypeValue && !positionName) {
            v.productTypeValue = '合计'
            v.positionName = '合计'
          } else if (positionName && positionName !== '合计' && (!productTypeValue || !productSymbolValue)) {
            if (!productTypeValue) v.productTypeValue = positionName
            if (!productSymbolValue) v.productSymbolValue = positionName
          } else if (productTypeValue === '规模款') {
            v.positionName = '规模款'
          }
        })
      } catch (e) {
        console.log('e: ', e)
      } finally {
        this.tableLoading = false
      }
    }

  }
}
</script>

<style>

</style>
